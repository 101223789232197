import {
  Column,
  Image,
  PageMainContent,
  PageWidgetContainer,
  Row,
} from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const Container = styled(PageMainContent)`
  display: flex;
  flex-direction: column;
  gap: ${toThemePixels(4)};
  padding-bottom: 0;
  height: unset;
`

export const CartContent = styled(Column)`
  gap: ${toThemePixels(4)};
  padding: 0 ${toThemePixels(4)};
`

export const StyledWidgetContent = styled(PageWidgetContainer)`
  > div {
    margin-top: 56px;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    grid-column: col-start 9 / span 3;
    position: relative;
    height: 100%;

    > * {
      position: absolute;
      bottom: 0;
      left: -${toThemePixels(6)};
      right: -${toThemePixels(6)};
      top: unset;
    }
  }

  @media (max-width: 980px) {
    display: none;
  }
`

export const CheckoutStepperActions = styled(Row)`
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 24px 24px;
  border-top: 1px solid ${themeColor(ThemeColor.b30)};
  z-index: 2;
  background-color: white;
  position: relative;
  border-radius: 0 0 24px 24px;

  > button:first-child {
    padding: 8px 16px 8px 8px;
  }

  @media (max-width: 655px) {
    flex-direction: column;
    border-radius: 0;
  }
`

export const ContactButtonImage = styled(Image)`
  height: 32px;
  width: 32px;
  border-radius: 100%;
`
