import { Typography } from '@/components'
import { BreadcrumbText, Step } from '@/components/organisms/Stepper/styles'
import { ThemeColor } from '@/enums'
import { CheckLine } from '@/icons'
import { themeColor, toThemePixels } from '@/utils'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

const BreadcrumbBorder = styled.div<{
  checked: boolean
  current: boolean
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: ${toThemePixels(1)};
  border: 2px solid ${themeColor(ThemeColor.info1)};
  border-color: ${ifProp(
    'current',
    themeColor(ThemeColor.info1),
    ifProp('checked', themeColor(ThemeColor.info1), themeColor(ThemeColor.b30)),
  )};
  border-radius: 100%;
  width: ${toThemePixels(5)};
  height: ${toThemePixels(5)};
  background: ${ifProp(
    'checked',
    themeColor(ThemeColor.info1),
    themeColor(ThemeColor.b0),
  )};
  z-index: 2;

  span {
    font-weight: 700;
    font-size: 10px;
    text-align: center;
  }

  svg {
    display: block;
    padding: 6px;
  }
`

const Wrapper = styled.div`
  display: flex;
  margin: 8px 0;
`

interface Props {
  steps: string[]
  current: number
}

export const CheckoutStepperBreadcrumbs = ({ steps, current }: Props) => (
  <Wrapper>
    {steps.map((stepNr, i) => (
      <Step key={`${stepNr}`} current={current === i} done={current > i}>
        <BreadcrumbBorder checked={current > i} current={current === i}>
          <Typography
            color={current === i ? ThemeColor.info1 : ThemeColor.b50}
            variant="captionR"
          >
            {current > i ? (
              <CheckLine color={ThemeColor.b0} size={20} />
            ) : (
              i + 1
            )}
          </Typography>
        </BreadcrumbBorder>
        <BreadcrumbText current={current === i} done={current > i}>
          {stepNr}
        </BreadcrumbText>
      </Step>
    ))}
  </Wrapper>
)

CheckoutStepperBreadcrumbs.propTypes = {
  current: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string),
}
