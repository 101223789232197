import { createContext, useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

type LocationContextType = {
  locationId: string | null
  lastKnownLocationId: string | null
  setLastKnownLocationId: (locationId: string | null) => void
  clearLocation: () => void
}
const LocationContext = createContext<LocationContextType>({
  locationId: null,
  lastKnownLocationId: null,
  setLastKnownLocationId: () => {},
  clearLocation: () => {},
})

export const useLocationContext = () => {
  const context = useContext(LocationContext)
  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider')
  }
  return context
}

export const LocationProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[]
}) => {
  const { locationId: currentLocationId } = useParams()
  const [lastKnownLocationId, setLastKnownLocationId] = useState<string | null>(
    null,
  )
  const locationId = currentLocationId || lastKnownLocationId

  useEffect(() => {
    if (currentLocationId) {
      setLastKnownLocationId(currentLocationId || null)
    }
  }, [currentLocationId])

  const clearLocation = () => {
    setLastKnownLocationId(null)
  }

  return (
    <LocationContext.Provider
      value={{
        locationId,
        lastKnownLocationId,
        setLastKnownLocationId,
        clearLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}
