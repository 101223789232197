import styled from 'styled-components'

export const Container = styled.div.attrs({
  'data-testid': 'loader',
})`
  width: 100vw;
  height: 100vh;
  z-index: 15000;
  position: fixed;
  left: 0;
  top: 0;
  background: ${(props) => props.theme.color.b10};

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
