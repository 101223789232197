import { any, find, findIndex, propEq } from 'ramda'
import React, { useState } from 'react'

interface StepState {
  index: number
  step: string
  data: {
    title?: string
    subtitle?: string
    tooltip?: React.ReactNode
  }
  isFirst: boolean
  isLast: boolean
  back: () => void
  next: () => void
  goTo: (step: string) => void
}

export const useSteps = ({
  initialStep,
  steps,
  keyPropName,
}: any): StepState => {
  const isInitialInSteps = any(propEq(initialStep, 'key'))(steps)

  const getStep = (i: number) => steps[i][keyPropName]

  const [step, setStep] = useState(isInitialInSteps ? initialStep : getStep(0))

  const isCurrentStep = propEq(step, keyPropName)

  const data = find(isCurrentStep, steps) || {
    title: undefined,
    subtitle: undefined,
    tooltip: undefined,
  }
  const index = findIndex(isCurrentStep, steps)

  const isLast = index === steps.length - 1

  const isFirst = index === 0

  const back = () => setStep(getStep(index - 1))

  const next = () => setStep(getStep(index + 1))

  const goTo = setStep

  return { step, isLast, isFirst, index, data, back, next, goTo }
}
