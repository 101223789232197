import { ThemeColor } from '@/enums'
import { BurgerLine, PhoneLine, SureinLogoNew } from '@/icons'
import { useAuth, useContactModal } from '@/providers'
import { paths } from '@/routes/paths'
import { dynamicObjectPropType, isVerified, useGoToPath } from '@/utils'
import { useSidebar } from '@surein/ui'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { IconButton, LanguageSwitch } from '../../atoms'
import { ShowWhen } from '../../hoc/ShowWhen'
import { Relative, Row } from '../../styled'
import { Container } from './styles'

export const HeaderBase = ({
  backgroundColor,
  hasHeadquarters,
  hidden,
  border,
  onLogoClick,
  hide = {},
}) => {
  const { openContact } = useContactModal({
    hasHeadquarters,
    hide,
  })
  const { toggleSidebar, openMobile: isOpenMobile } = useSidebar()

  return (
    <Container
      backgroundColor={backgroundColor}
      border={border}
      hidden={hidden}
      isOpenMobile={isOpenMobile}
    >
      <Relative>
        <SureinLogoNew height={24} onClick={onLogoClick} width={88} />
      </Relative>
      <Row alignItems="center" gap="8px">
        <ShowWhen when={!hide.language}>
          <LanguageSwitch />
        </ShowWhen>
        <IconButton
          aria-label="contact button"
          onClick={openContact}
          size="medium"
          variant="outlined"
        >
          <PhoneLine color={ThemeColor.b0} size="info" />
        </IconButton>
        <ShowWhen when={!hide.menu}>
          <IconButton onClick={toggleSidebar} size="medium" variant="outlined">
            <BurgerLine size="info" stroke />
          </IconButton>
        </ShowWhen>
      </Row>
    </Container>
  )
}

HeaderBase.propTypes = {
  backgroundColor: PropTypes.string,
  border: PropTypes.bool,
  cart: dynamicObjectPropType,
  companies: PropTypes.array,
  hasHeadquarters: PropTypes.bool,
  hidden: PropTypes.bool,
  hide: PropTypes.shape({
    contact: PropTypes.shape({
      chat: PropTypes.bool,
      header: PropTypes.bool,
    }),
    language: PropTypes.bool,
    menu: PropTypes.bool,
  }),
  onLogoClick: PropTypes.func,
  userCompanies: PropTypes.array,
}

export const Header = ({
  backgroundColor,
  hasHeadquarters,
  hidden,
  border,
  onLogoClick,
  hideContact,
  simple,
  shouldShowSidebar,
}) => {
  const { isAuthenticated, isLoading, user } = useAuth()
  const { pathname } = useLocation()

  const goToRoot = useGoToPath(paths.authRedirect)

  const shouldRenderMenu =
    isAuthenticated && isVerified(user) && !simple && shouldShowSidebar

  const shouldRenderLanguage =
    !isLoading &&
    (!isAuthenticated ||
      pathname.includes('assessment') ||
      pathname.includes('funnel'))

  return (
    <HeaderBase
      backgroundColor={backgroundColor}
      border={border}
      hasHeadquarters={hasHeadquarters}
      hidden={hidden}
      hide={{
        contact: hideContact,
        language: !shouldRenderLanguage,
        menu: !shouldRenderMenu,
      }}
      onLogoClick={onLogoClick || goToRoot}
    />
  )
}

Header.propTypes = {
  backgroundColor: PropTypes.string,
  border: PropTypes.bool,
  hasHeadquarters: PropTypes.bool,
  hidden: PropTypes.bool,
  hideContact: PropTypes.shape({
    chat: PropTypes.bool,
    header: PropTypes.bool,
  }),
  onLogoClick: PropTypes.func,
  shouldShowSidebar: PropTypes.bool,
  simple: PropTypes.bool,
}
