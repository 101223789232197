import {
  ErrorBoundary,
  Header,
  Loader,
  PageLayout,
  PageWrapper,
} from '@/components'
import { SideBar } from '@/components/molecules/SideBar'
import {
  IGNORED_ANALYTICS_PATHS,
  usePageView,
  useSegment,
} from '@/modules/analytics'
import { AccessProvider, LocationProvider, useAuth } from '@/providers'
import { LanguageIndicator } from '@/routes/LanguageIndicator'
import { useCarriers, useCart, useCompanies, useUserProfile } from '@/services'
import {
  checkIsInPartnerUrl,
  initGTM,
  initHotjar,
  isAdmin,
  isVerified,
  noop,
  useIsInPath,
  useIsMobile,
} from '@/utils'
import { useMarketingCookies } from '@/utils/hooks/useMarketingCookies'
import * as Sentry from '@sentry/react'
import { any, map, prop } from 'ramda'
import { useEffect } from 'react'
import {
  Navigate,
  Outlet,
  createBrowserRouter,
  useLocation,
} from 'react-router-dom'
import { config } from '../config'
import { PreventMobileZoom } from './PreventMobileZoom'
import { ScrollToTop } from './ScrollToTop'
import { UnauthorizedGuard } from './UnauthorizedGuard'
import { routes, simpleHeaderRoutes, transformRoutes } from './routes'

const darkPageLayoutRoutes = ['recommendation', 'checkout']

const headerHiddenRoutes = [
  'assessment',
  'funnel',
  '/ssf/offer',
  // TODO: change to a better determinator, good for now
  'qonto',
]

const sidebarHiddenRoutes = [
  'assessment',
  'checkout',
  'ssf/offer',
  'order-confirmation',
  'recommendation',
]

const headerBorderRoutes = [
  'checkout',
  'ssf/offer',
  'cart',
  'order-confirmation',
  'recommendation',
]

const isPathInRoutes = (pathname, applicableRoutes) => {
  return applicableRoutes.some((route) => pathname.includes(route))
}

const makeCarrierOptions = map(({ name, carrierId, logoUrl }) => ({
  text: name,
  value: carrierId,
  icon: [config.cdn, logoUrl].join('/'),
}))

const hasProtectedAccess = (userCompanies = []) =>
  userCompanies.some(
    (company) => company?.userType?.MD || company?.userType?.owner,
  ) || false

const hasHeadquarters = (data = []) => any(prop('isHeadquarters'), data)

const isInPartnerRecommendation = (pathname) =>
  checkIsInPartnerUrl() && pathname.includes('recommendation')

const findPartnerSource = (companies = []) => {
  return (
    companies.find((company) => company.partnerSource !== null)
      ?.partnerSource || null
  )
}

initGTM()
initHotjar()

const AppSkeleton = () => {
  const { pathname } = useLocation()
  const { isMobile } = useIsMobile()
  const { isAuthenticated, user } = useAuth()
  const shouldFetch = Boolean(
    isAuthenticated && isVerified(user) && !isAdmin(user),
  )
  const { marketingPid, marketingMid } = useMarketingCookies()

  const analytics = useSegment()

  const userProfileResponse = useUserProfile({
    enabled: shouldFetch,
  })

  const companiesResponse = useCompanies({
    enabled: shouldFetch,
  })

  useEffect(() => {
    if (userProfileResponse.data?.userId) {
      analytics.identify(userProfileResponse.data.userId, {
        email: userProfileResponse.data.email,
        first_name: userProfileResponse.data.name,
        last_name: userProfileResponse.data.surname,
        partner_source: findPartnerSource(companiesResponse?.data),
      })
    }
  }, [userProfileResponse.data, companiesResponse.data])

  const carriersResponse = useCarriers({
    enabled: shouldFetch,
    select: makeCarrierOptions,
  })

  const cartResponse = useCart({
    enabled: shouldFetch,
  })

  const isSimpleHeader = useIsInPath(...simpleHeaderRoutes)

  const shouldShowDarkPageLayout = isPathInRoutes(
    pathname,
    darkPageLayoutRoutes,
  )
  const shouldShowSidebar = !isPathInRoutes(pathname, sidebarHiddenRoutes)
  const shouldShowHeader = !isPathInRoutes(pathname, headerHiddenRoutes)
  const shouldShowHeaderBorder = isPathInRoutes(pathname, headerBorderRoutes)

  usePageView(IGNORED_ANALYTICS_PATHS, routes)

  if (
    userProfileResponse.isLoading ||
    companiesResponse.isLoading ||
    cartResponse.isLoading
  )
    return <Loader />

  return (
    <ErrorBoundary>
      <AccessProvider>
        <LocationProvider>
          <PreventMobileZoom />
          <ScrollToTop />
          <UnauthorizedGuard />
          {shouldFetch && <LanguageIndicator data={userProfileResponse.data} />}
          <PageLayout
            dark={shouldShowDarkPageLayout}
            simple={pathname.includes('assessment') || isSimpleHeader}
          >
            {(isMobile || !shouldShowSidebar) && (
              <Header
                border={shouldShowHeaderBorder}
                cart={cartResponse.data || {}}
                companies={companiesResponse.data || []}
                hasHeadquarters={hasHeadquarters(companiesResponse.data)}
                hidden={!shouldShowHeader}
                simple={isSimpleHeader}
                userCompanies={userProfileResponse.data?.companies || []}
                onLogoClick={
                  isInPartnerRecommendation(pathname) ? noop : undefined
                }
                shouldShowSidebar={shouldShowSidebar}
              />
            )}
            <PageWrapper>
              <SideBar
                cart={cartResponse.data || {}}
                companies={companiesResponse.data || []}
                userCompanies={userProfileResponse.data?.companies || []}
                hide={!shouldShowSidebar}
              />
              <Outlet
                context={{
                  user: userProfileResponse.data,
                  companies: companiesResponse.data,
                  carriers: carriersResponse.data || [],
                  cart: cartResponse.data || {},
                  marketingPid: marketingPid || null,
                  marketingMid: marketingMid || null,
                  hasProtectedAccess: hasProtectedAccess(
                    userProfileResponse.data?.companies,
                  ),
                  hasHeadquarters: hasHeadquarters(companiesResponse.data),
                }}
              />
            </PageWrapper>
          </PageLayout>
        </LocationProvider>
      </AccessProvider>
    </ErrorBoundary>
  )
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
)

export const router = sentryCreateBrowserRouter([
  {
    element: <AppSkeleton />,
    children: [
      {
        path: '/',
        children: transformRoutes(routes),
      },
      {
        path: '/en',
        children: transformRoutes(routes),
      },
      {
        path: '/de',
        children: transformRoutes(routes),
      },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
])
