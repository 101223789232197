import { useAuth } from '@/providers'
import { useLocationContext } from '@/providers/LocationProvider'
import { paths } from '@/routes/paths'
import { Address } from '@/types/address'
import { UserCompany } from '@/types/company'
import { CartRecommendationProduct } from '@/types/recommendation'
import { formatRiskAddress } from '@/utils/address'
import {
  AppSidebar,
  Button,
  LinkComponentProps,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  sidebarStates,
  useSidebar,
} from '@surein/ui'
import {
  Cog,
  Files,
  Flag,
  LayoutDashboard,
  LogOut,
  MapPin,
  MessageCircle,
  PlusCircle,
  Search,
  ShoppingBag,
} from 'lucide-react'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
const LogoComponent = () => {
  const { state } = useSidebar()

  return state === sidebarStates.expanded ? (
    <Link to="/">
      <img alt="SureIn" className="my-1 w-20" src="/assets/surein-logo.svg" />
    </Link>
  ) : (
    <Link to="/">
      <img
        alt="SureIn"
        className="my-1 h-6 w-6"
        src="/assets/surein-logo-small.svg"
      />
    </Link>
  )
}

const FooterComponent = () => {
  const { state } = useSidebar()
  const { logout } = useAuth()
  const { t } = useTranslation()
  return state === sidebarStates.expanded ? (
    <div>
      <Button
        icon={LogOut}
        shape="square"
        size="sm"
        variant="ghost"
        onClick={() => logout()}
      >
        {t('profile.menu.logOut')}
      </Button>
    </div>
  ) : (
    <SidebarMenu>
      <SidebarMenuItem className="cursor-pointer list-none">
        <SidebarMenuButton asChild tooltip="Log out" onClick={logout}>
          <LogOut />
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}

interface UseSidebarItemsProps {
  cart: Record<string, CartRecommendationProduct[]>
  companies: {
    companyId: string
    name: string
    locations: {
      locationId: string
      name: string
      address: Address
    }[]
  }[]
  userCompanies: UserCompany[]
  locationId: string | null
}

const useSidebarItems = ({
  cart,
  companies,
  userCompanies,
  locationId,
}: UseSidebarItemsProps) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const currentCompanyId = companies?.find((company) =>
    company.locations.find((location) => location.locationId === locationId),
  )?.companyId

  const permissions = userCompanies?.find(
    (company: UserCompany) => company.companyId === currentCompanyId,
  )?.userType

  const canSeeCart = permissions?.MD || permissions?.owner

  const formatLocation = (location: {
    locationId: string
    name: string
    address: Address
  }) => {
    const formattedAddress = formatRiskAddress(location.address)
    const displayAddress =
      formattedAddress.includes('null') ||
      formattedAddress.includes('undefined')
        ? ''
        : formattedAddress

    return {
      locationId: location.locationId,
      name: location.name,
      logo: MapPin,
      address: displayAddress,
      url: `${paths.viewLocationWithKey(location.locationId)}`,
    }
  }

  const formatCompany = (company: {
    companyId: string
    name: string
    locations: {
      locationId: string
      name: string
      address: Address
    }[]
  }) => ({
    companyId: company.companyId,
    name: company.name === 'company name' ? t('common.company') : company.name,
    locations: company.locations.map(formatLocation),
  })

  return {
    companies: companies?.map(formatCompany),
    navItems: [
      {
        title: t('navigation.addContracts'),
        url: paths.addInsuranceContract,
        icon: PlusCircle,
        isActive: pathname.includes('/add-insurance'),
      },
      {
        title: t('navigation.home'),
        url: paths.dashboard,
        icon: LayoutDashboard,
        isActive: pathname === paths.dashboard,
      },
      {
        title: t('navigation.findInsurances'),
        url: locationId ? paths.findInsurancesWithKey(locationId) : '#',
        icon: Search,
        isActive: pathname.includes('/location/find-insurances'),
        hide: !locationId,
      },
      {
        title: t('navigation.orders'),
        url: locationId ? paths.viewCartWithKey(locationId) : '#',
        icon: ShoppingBag,
        badgeText: cart[locationId || '']?.length?.toString(),
        solidBadge: true,
        isActive: pathname.includes('/cart/'),
        hide: !locationId || !canSeeCart,
      },
      {
        title: t('navigation.contractsOverview'),
        url: '/contracts-overview',
        icon: Files,
        badgeText: 'Pro',
        isActive: pathname === '/contracts-overview',
      },
      {
        title: t('navigation.reportDamage'),
        url: paths.customerPortal,
        icon: Flag,
        isActive: pathname === paths.customerPortal,
      },
      {
        title: t('profile.menu.profile'),
        url: '/profile',
        icon: Cog,
        isActive: pathname.startsWith('/profile'),
        items: [
          {
            title: t('profile.titles.profile'),
            url: '/profile/profile',
            isActive: pathname === '/profile/profile',
          },
          {
            title: t('profile.menu.signPOA'),
            url: '/profile/poa',
            isActive: pathname === '/profile/poa',
          },
          {
            title: t('profile.menu.users'),
            url: '/profile/users',
            isActive: pathname === '/profile/users',
          },
          {
            title: t('profile.menu.companies'),
            url: '/profile/companies',
            isActive: pathname === '/profile/companies',
          },
          {
            title: t('profile.menu.payments'),
            url: '/profile/payments',
            isActive: pathname === '/profile/payments',
          },
          {
            title: t('profile.menu.language'),
            url: '/profile/language',
            isActive: pathname === '/profile/language',
          },
        ],
      },
      {
        title: t('navigation.services'),
        url: '/services',
        icon: MessageCircle,
        isActive: pathname === '/services',
      },
    ].filter((item) => !item.hide),
  }
}

const LinkComponent = forwardRef<HTMLAnchorElement, LinkComponentProps>(
  function LinkComponent({ to, children, ...props }, ref) {
    return (
      <Link to={to} ref={ref} {...props}>
        {children}
      </Link>
    )
  },
)

interface SideBarProps {
  cart: Record<string, CartRecommendationProduct[]>
  companies: {
    companyId: string
    name: string
    locations: {
      locationId: string
      name: string
      address: Address
    }[]
  }[]
  userCompanies: UserCompany[]
  hide: boolean
}

export const SideBar = ({
  cart,
  companies,
  userCompanies,
  hide,
}: SideBarProps) => {
  const { locationId } = useLocationContext()
  const { t } = useTranslation()
  const sidebarConfig = useSidebarItems({
    cart,
    companies,
    userCompanies,
    locationId,
  })

  return (
    <>
      {hide ? null : (
        <AppSidebar
          linkComponent={LinkComponent}
          data={sidebarConfig}
          logoComponent={LogoComponent}
          footerComponent={FooterComponent}
          activeLocationId={locationId}
          companySwitcherEmptyMessage={t('companySwitcherEmptyMessage')}
        />
      )}
    </>
  )
}
