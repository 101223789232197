import { toPixels, useIsMobile } from '@/utils'
import { useEffect, useRef } from 'react'
import { SecondaryPageWidgetContainer } from '../styled'

interface Props {
  children?: React.ReactNode
  dependencies?: any[]
}

export const ResponsivePageWidgetContainer = ({
  children,
  dependencies = [],
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { width } = useIsMobile()

  useEffect(() => {
    if (width <= 600 && ref.current) {
      const { offsetHeight } = (ref.current?.children[0] as HTMLElement) || {}

      ref.current.style.minHeight = toPixels(offsetHeight)
    }
  }, [width, ref.current, ...dependencies])

  return (
    <SecondaryPageWidgetContainer ref={ref}>
      {children}
    </SecondaryPageWidgetContainer>
  )
}
