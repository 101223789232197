import { makeQueryString } from '@/utils'
import { api } from './api'
import { Company } from '@/types/company'

export const getCompanies = () => api.get('/company')

interface CreateCompanyPayload {
  company: Record<string, any>
  location: Record<string, any>
}

export const createCompany = ({ company, location }: CreateCompanyPayload) =>
  api.post('/company', { company, location })

export const updateCompany = (id: string, { name }: { name: string }) =>
  api.put(`/company/${id}`, { name })

interface CreateCompanyUserPayload {
  name: string
  surname: string
  email: string
  phone: string
  isMd: boolean
}

export const createCompanyUser = (
  id: string,
  { name, surname, email, phone, isMd }: CreateCompanyUserPayload,
) => api.post(`/company/add-user/${id}`, { name, surname, email, phone, isMd })

export const getCompanyById = (id: string): Promise<Company> =>
  api.get(`/company/${id}`)

export const deleteCompany = (id: string) => api.delete(`/company/${id}`)

interface SignPoaPayload {
  companyId: string
  signature: string
  birthdate: string
}

export const signPoa = ({ companyId, signature, birthdate }: SignPoaPayload) =>
  api.post(`/home/poa-signature/${companyId}`, { signature, birthdate })

export const getContractsOverview = (archivedView = false) =>
  api.get(
    `/company/insurances${makeQueryString({
      archivedView,
    })}`,
  )
