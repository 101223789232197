import {
  BackButton,
  ButtonLink,
  CartCard,
  CheckoutPriceCard,
  Column,
  Loader,
  ShowWhen,
  TextWithLink,
  Typography,
} from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { useContactModal } from '@/providers'
import { paths } from '@/routes/paths'
import { Redirect } from '@/routes/Redirect'
import { useLocation } from '@/services'
import {
  mapWithKey,
  toPixels,
  useGoBack,
  useGoToPath,
  useIsMobile,
} from '@/utils'

import { Button } from '@surein/ui'
import { ArrowRight } from 'lucide-react'
import { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'
import {
  CartContent,
  CheckoutStepperActions,
  ContactButtonImage,
  Container,
  StyledWidgetContent,
} from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('navigation.orders'),
    emptyCart: t('emptyCart'),
    location: t('location'),
    insurance: t('common.insurance'),
    insurances: t('insurances'),
    totalPrice: t('totalPrice'),
    next: t('next'),
  }
}

const ContactButtonIcon = () => (
  <ContactButtonImage name="chatblock" type="png" />
)

const EmptyCart = () => {
  const messages = useMessages()
  const { locationId } = useParams()

  const goToFindInsurances = useGoToPath(
    paths.findInsurancesWithKey(locationId),
  )

  return (
    <TextWithLink>
      <Trans
        components={
          <ButtonLink
            color={ComponentColors.primary}
            onClick={goToFindInsurances}
          />
        }
        i18nKey={messages.emptyCart}
      />
    </TextWithLink>
  )
}

export const CartPage = () => {
  const widgetRef = useRef()
  const { isMobile } = useIsMobile()
  const messages = useMessages()
  const { locationId } = useParams()
  const { data: locationData, isLoading: isLocationDataLoading } = useLocation(
    locationId,
  )
  const goToCheckout = useGoToPath(paths.viewCheckoutWithKey(locationId))
  const { cart, user } = useOutletContext()
  const { t } = useTranslation()
  const { openContact } = useContactModal()
  const goBack = useGoBack()

  const data = cart[locationId] || []

  useEffect(() => {
    if (isMobile && widgetRef.current) {
      const { offsetHeight } = widgetRef.current.children[0]

      widgetRef.current.style.minHeight = toPixels(offsetHeight)
    }
  }, [isMobile, widgetRef.current])

  if (isLocationDataLoading) return <Loader />

  const permissions = user?.companies?.find(
    (company) => company.companyId === locationData?.companyId,
  )?.userType

  const canAccessCart = permissions.MD || permissions.owner

  if (locationData && !canAccessCart)
    return <Redirect to={paths.authRedirect} />

  return (
    <>
      <Container>
        <BackButton onBack={goBack} />
        <Column justifyContent="space-between" gap="16px" flex={1}>
          <CartContent>
            <Typography variant="h3">{messages.title}</Typography>
            {locationData?.name && (
              <Typography
                bold
                color={ThemeColor.b50}
                singleLine
                variant="inputDescription"
              >
                {locationData.name}
              </Typography>
            )}
            <ShowWhen fallback={EmptyCart} when={data.length}>
              {mapWithKey(CartCard, 'recommendationProductId', data)}
            </ShowWhen>
          </CartContent>
          <CheckoutStepperActions>
            <ShowWhen when={!isMobile}>
              <Button
                icon={ContactButtonIcon}
                onClick={openContact}
                variant="outline"
              >
                {t('common.contactUs')}
              </Button>
              <Button
                disabled={!data.length}
                onClick={goToCheckout}
                reverted
                suffixIcon={() => <ArrowRight size={18} />}
              >
                {t('next')}
              </Button>
            </ShowWhen>
            <ShowWhen when={isMobile}>
              <Button
                className="w-full"
                onClick={goToCheckout}
                reverted
                suffixIcon={() => <ArrowRight size={18} />}
              >
                {t('next')}
              </Button>
            </ShowWhen>
          </CheckoutStepperActions>
        </Column>
      </Container>
      <ShowWhen when={data.length}>
        <StyledWidgetContent ref={widgetRef} showOnMobile>
          <CheckoutPriceCard
            buttonText={messages.next}
            data={data}
            hideProducts
          />
        </StyledWidgetContent>
      </ShowWhen>
    </>
  )
}
