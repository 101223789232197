import {
  Breakpoint,
  BreakpointType,
  ComponentColors,
  ThemeColor,
} from '@/enums'
import { DefaultTheme, useTheme } from 'styled-components'
import { toPixels } from './convert'
import { isString } from './type'

const fromTheme = (...properties: string[]) => ({
  theme,
}: {
  theme: DefaultTheme
}) => {
  return properties.reduce((acc: any, key: string) => acc?.[key], theme)
}

export const themeColor = (color: string) => fromTheme('color', color)

export const themeColorWith = (
  modifier: (value: any) => any,
  color: string,
) => ({ theme }: { theme: DefaultTheme }) =>
  modifier(fromTheme('color', color)({ theme }))

export const themeColorFrom = (fn: (props: any) => string) => (props: {
  theme: DefaultTheme
}) => {
  const color = fn(props)
  return themeColor(color)
}

export const themeColorFromProp = (propName: string) =>
  themeColorFrom((props: Record<string, any>) => props[propName])

export const themeColorFromPropColor = themeColorFromProp('color')

export const themeColorSwitch = (
  cases: Record<string, string>,
  defaultCase: string,
) =>
  themeColorFrom((props: { color?: string }) =>
    Object.prototype.hasOwnProperty.call(cases, props.color || '')
      ? cases[props.color!]
      : defaultCase,
  )

export const primaryColorFromComponentColor = (defaultCase: string) =>
  themeColorSwitch(
    {
      [ComponentColors.primary]: ThemeColor.info1,
      [ComponentColors.success]: ThemeColor.success1,
      [ComponentColors.warning]: ThemeColor.warning1,
      [ComponentColors.danger]: ThemeColor.danger1,
      [ComponentColors.text]: ThemeColor.b100,
    },
    defaultCase,
  )

export const secondaryColorFromComponentColor = (defaultCase: string) =>
  themeColorSwitch(
    {
      [ComponentColors.primary]: ThemeColor.info2,
      [ComponentColors.success]: ThemeColor.success2,
      [ComponentColors.warning]: ThemeColor.warning2,
      [ComponentColors.danger]: ThemeColor.danger2,
      [ComponentColors.text]: ThemeColor.b50,
    },
    defaultCase,
  )

export const trinaryColorFromComponentColor = (defaultCase: string) =>
  themeColorSwitch(
    {
      [ComponentColors.primary]: ThemeColor.info3,
      [ComponentColors.success]: ThemeColor.success3,
      [ComponentColors.warning]: ThemeColor.warning3,
      [ComponentColors.danger]: ThemeColor.danger3,
    },
    defaultCase,
  )

export const quaternaryColorFromComponentColor = (defaultCase: string) =>
  themeColorSwitch(
    {
      [ComponentColors.primary]: ThemeColor.info4,
      [ComponentColors.success]: ThemeColor.success4,
      [ComponentColors.warning]: ThemeColor.warning4,
      [ComponentColors.danger]: ThemeColor.danger4,
    },
    defaultCase,
  )

export const useThemeColor = (color: string): string => {
  const theme = useTheme()
  return themeColor(color)({ theme })
}

export const themeFontWeight = (fontWeight: string) =>
  fromTheme('font', 'weight', fontWeight)

export function toThemePixels(
  fontSizePx: string | number,
): (props: { theme: DefaultTheme; [key: string]: any }) => string {
  return (props) => {
    const { theme } = props
    const baseFontSize = theme?.baseFontSize ?? 1
    const propValue = isString(fontSizePx) ? props[fontSizePx] : fontSizePx

    return toPixels(baseFontSize * propValue)
  }
}

export function pixelsFromProp(propName: string) {
  return (props: Record<string, any>): string => {
    return toPixels(props[propName])
  }
}

const breakpointValues = Object.values(Breakpoint)
const breakpointTypeValues = Object.values(BreakpointType)

const hasType = (type: keyof typeof BreakpointType) =>
  breakpointTypeValues.includes(type)
const hasBreakpoint = (breakpoint: keyof typeof Breakpoint) =>
  breakpointValues.includes(breakpoint)

export const themeBreakpoint = (
  type: keyof typeof BreakpointType,
  breakpoint: keyof typeof Breakpoint,
) => ({ theme }: { theme: DefaultTheme }) => {
  if (!hasType(type)) {
    throw new Error(
      `Unknown method '${type}'. Use one of ${breakpointTypeValues}`,
    )
  }

  if (!hasBreakpoint(breakpoint)) {
    throw new Error(
      `Unknown breakpoint '${breakpoint}'. Use one of ${breakpointValues}`,
    )
  }

  const withDirection = theme?.breakpoints?.[type]

  if (typeof withDirection !== 'function') {
    throw new Error(`There is no breakpoint ${type}`)
  }

  return withDirection(breakpoint)
}

export const themeBreakpointUp = (breakpoint: keyof typeof Breakpoint) =>
  themeBreakpoint(BreakpointType.up, breakpoint)

export const themeBreakpointDown = (breakpoint: keyof typeof Breakpoint) =>
  themeBreakpoint(BreakpointType.down, breakpoint)

export const themeBreakpointBetween = (
  start: keyof typeof Breakpoint,
  end: keyof typeof Breakpoint,
) => ({ theme }: { theme: DefaultTheme }) => {
  if (!hasBreakpoint(start)) {
    throw new Error(
      `Unknown breakpoint start '${start}'. Use one of ${breakpointValues}`,
    )
  }

  if (!hasBreakpoint(end)) {
    throw new Error(
      `Unknown breakpoint end '${end}'. Use one of ${breakpointValues}`,
    )
  }

  const withDirection = theme?.breakpoints?.between

  if (typeof withDirection !== 'function') {
    throw new Error(`There is no breakpoint between for ${start} and ${end}`)
  }

  return withDirection(start, end)
}

export function themeTransition(
  attr: string = 'all',
  duration: string = '0.4s',
): (props: { theme: DefaultTheme }) => string {
  return ({ theme }) => {
    const transitionFn = theme?.transition ?? 'ease'
    return [attr, duration, transitionFn].join(' ')
  }
}

export const componentColorToPrimaryColorMap = {
  [ComponentColors.primary]: ThemeColor.info1,
  [ComponentColors.success]: ThemeColor.success1,
  [ComponentColors.warning]: ThemeColor.warning1,
  [ComponentColors.danger]: ThemeColor.danger1,
  [ComponentColors.text]: ThemeColor.b100,
}
