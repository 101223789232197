import { useMutation, useQueryClient } from 'react-query'
import { pack } from '../list'

export const useInvalidateOnSuccessMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = any,
  TContext = unknown
>(
  keyToInvalidate:
    | string
    | string[]
    | string[][]
    | (string | string[])[]
    | Record<string, string>,
  mutationFn: (variables: TVariables) => Promise<TData>,
) => {
  const queryClient = useQueryClient()
  return useMutation<TData, TError, TVariables, TContext>(mutationFn, {
    onSuccess: () => {
      pack(keyToInvalidate).forEach((key) => queryClient.invalidateQueries(key))
    },
  })
}
