import { Location } from '@/types/location'
import { updateAddress } from './address'
import { api } from './api'
import { Address } from '@/types/address'

export const getLocationById = (id: string): Promise<Location> =>
  api.get(`/location/${id}`)

export const getLocationHome = (id: string): Promise<any> =>
  api.get(`/location/${id}/home`)

export const createLocation = (data: {
  name: string
  address: Address
  companyId: string
}): Promise<any> => api.post('/location', data)

export const updateLocation = (data: {
  id: string
  name: string
}): Promise<any> => api.put(`/location/${data.id}`, { name: data.name })

export const updateLocationEssentialInsurances = (data: {
  id: string
  essentialCategoryId: string
}): Promise<any> =>
  api.put(`/location/${data.id}`, {
    essentialCategoryId: data.essentialCategoryId,
  })

export const updateLocationWithAddress = (data: {
  id: string
  name: string
  address: Address
}): Promise<any[]> =>
  Promise.all([
    updateAddress(data.address),
    updateLocation({ id: data.id, name: data.name }),
  ])

export const deleteLocation = (id: string): Promise<any> =>
  api.delete(`/location/${id}`)

export const requestDeleteLocation = (id: string): Promise<any> =>
  api.post(`/request/delete`, {
    resType: 'location',
    resId: id,
  })

export const requestDeleteCompany = (id: string): Promise<any> =>
  api.post(`/request/delete`, {
    resType: 'company',
    resId: id,
  })

export const requestEditLocation = (id: string): Promise<any> =>
  api.post(`/request/edit`, {
    resType: 'location',
    resId: id,
  })

export const requestEditCompany = (id: string): Promise<any> =>
  api.post(`/request/edit`, {
    resType: 'company',
    resId: id,
  })

export const requestEditUser = (id: string): Promise<any> =>
  api.post(`/request/edit`, {
    resType: 'user',
    resId: id,
  })

export const getCompanyPoaStatus = (id: string): Promise<any> =>
  api.get(`/company/check-poa/${id}`)

export const updateLocationChecklistSettings = (id: string): Promise<any> =>
  api.put(`/location/${id}`, {
    riskChecklistSettings: {
      skipContractAdded: true,
    },
  })
