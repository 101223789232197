import { useParams } from 'react-router-dom'
import { Base } from './Base'
import {
  ProfileCompanies,
  ProfileData,
  ProfilePayments,
  ProfileUsers,
  EditLanguagePage,
  ProfilePOA,
  ProfileIntegrations,
} from './components'
import { useLocationContext } from '@/providers/LocationProvider'
import { useEffect } from 'react'

export const Profile = () => {
  const { section } = useParams()
  const { clearLocation } = useLocationContext()

  useEffect(() => {
    clearLocation()
  }, [clearLocation])

  switch (section) {
    case 'payments':
      return <ProfilePayments />
    case 'profile':
      return <ProfileData />
    case 'users':
      return <ProfileUsers />
    case 'companies':
      return <ProfileCompanies />
    case 'poa':
      return <ProfilePOA />
    case 'integrations':
      return <ProfileIntegrations />
    case 'language':
      return <EditLanguagePage />
    default:
      return <Base />
  }
}
